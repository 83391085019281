<template>
  <div class="lhj-lottery">
    <titlebar titleName="活动抽奖"></titlebar>
    <div class="content1" :style="{ height: '245vw' }">
      <div class="lhzcontent"></div>
      <SlotMachine
        ref="myLucky"
        width="53vw"
        height="23vw"
        :slots="slots"
        :prizes="prizes"
        :defaultConfig="defaultConfig"
        class="lhj"
      />
      <div class="btn" @click="begin"></div>
      <div class="btn1">
        <div class="text">
          <p>剩余次数 {{ num }}</p>
        </div>
      </div>
      <div class="board">
        <div class="text">1.  活动时间：2021.12.23～1.31；<br>
        2.  活动期间，用户登陆即送一次抽奖机会；<br>
        3.  奖品使用规则请在“我的-中奖纪录”中查看；<br>
        4.  活动期间，用户分享好友成功即获得1次抽奖机会；<br>
        5.  活动期间，用户邀请好友成功即获得1次抽奖机会；<br>
        6.  活动期间，用户邀请好友超过10人抽奖机会翻倍；<br>
        7.  本活动的最终解释权归六一游趣所有。</div>
        <!-- <div class="text1">{{ data }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import titlebar from "../components/index/titleBar.vue";
import { SlotMachine } from "@lucky-canvas/vue";
import apiFun from "../servers/apiFun";
import comTool from "../assets/js/until"

export default {
  components: {
    titlebar,
    SlotMachine,
  },
  data() {
    return {
      // mmh: "",
      data: {},
      num: 3,
      index: Number,
      timer: null,
      second: 0,
      
      blocks: [
        { padding: '10px', background: '#869cfa' },
        { padding: '10px', background: '#e9e8fe' },
      ],
      slots: [
        { speed: 8, direction: -1 },
        { speed: 8, direction: 1 },
        { speed: 8, direction: -1 },
      ],
      prizes: [
        {
          background: "red",
          borderRadius: "1vw",
        },
        {
          background: "black",
          borderRadius: "1vw",
        },
        {
          background: "green",
          borderRadius: "1vw",
        },
      ],
      defaultConfig: {
        rowSpacing: "20px",
        colSpacing: "0",
      },
      isbegin: 1,
    };
  },
  created() {
    this.index = Math.floor(Math.random() * 8) + 1;
    // this.mmh = document.documentElement.clientHeight + "px";
  },
  mounted() {
    console.log(this.$refs.myLucky.stop);
  },
  methods: {
    begin() {
      if (this.num > 0){
        this.$refs.myLucky.play();
          // 假设接口的请求速度是5s
        setTimeout(() => {
          // 5s后拿到后端返回的中奖索引
          // const index = 0;
          // 然后停止游戏 (缓慢停止)
          this.$refs.myLucky.stop(0);
        }, 5000);
        this.num -= 1;
      }
    },
    getData() {
      let data = {
        gid: "100682",
        pid: "100",
        mid: "10000",
        p_mid: "1",
        device_id: "00e7efa241dae8842c8286cf69508e9c",
        client: "h5",
        client_id: "00e7efa241dae8842c8286cf69508e9c",
        tm: comTool.getTm(),
        user_id: $cookies.get("uid"),
        uname: $cookies.get("muname"),
        game_id: this.gameID,
      };
      data.sign = comTool.querySort(data);
      apiFun.Rule(data).then((res) => {
        this.data = res.data;
      });
    }
  },
};
</script>

<style lang="less"  scope>
.lhj-lottery {
  overflow: hidden;
}
.content1 {
  margin-top: 13vw;
  background-image: url("../assets/images/lhzbanner.png");
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  image-rendering: -webkit-optimize-contrast;
  .lhzcontent {
    width: 93.5vw;
    height: 141vw;
    background-image: url("../assets/images/lhzhz.png");
    background-repeat: no-repeat;
    background-size: 93.5vw 141vw;
    position: relative;
    top: 7vw;
    z-index: 999;
  }
  .lhj {
    position: relative;
    top: -63.5vw;
    left: 24.7vw;
  }
  .btn {
    width: 50vw;
    height: 15vw;
    // background: red;
    position: relative;
    top: -66vw;
    left: 30vw;
    z-index: 1000;
  }
  .btn1 {
    position: relative;
    width: 37vw;
    height: 10vw;
    background-image: url("../assets/images/lhjnum.png");
    background-size: 37vw 10vw;
    line-height: 10vw;
    top: -25vw;
    margin: auto;
    .text {
      position: relative;
      left: 8vw;
      font-family: Source Han Sans CN;
      color: #EF2823;
      font-size: 4.2vw;
      font-weight: 550;
    }
  }
  .board {
    position: relative;
    background-image: url("../assets/images/lhjboard.png");
    background-size: 93vw 69vw;
    width: 93vw;
    height: 69vw;
    line-height: 7vw;
    top: -19vw;
    margin: auto;
    .text {
      position: relative;
      font-family: Source Han Sans CN;
      color: #DC1912;
      top: 15vw;
      left: 6vw;
      font-size: 3.5vw;
      font-weight: 550;
      text-align: left;
    }
  }
}
</style>